<template>
  <div class="service-times-presentations template-2">
    <header class="page-header container mx-lg">
      <h1>{{ translations.tcCandidateDetails }}</h1>
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </header>
    <page-body class="mx-lg" v-if="individualProfile">
      <section class="section-1">
        <div class="bg-block p-4">
          <header class="mb-2">
            <h4>{{ indDisplayName }}</h4>
          </header>
          <div class="d-flex row mb-2 details2">
            <div class="col">
              <div class="detail">
                <strong>{{ translations.tcChurchMembership }}:</strong>
                <span>{{ individualProfile.church_name }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcDenomination }}:</strong>
                <span>{{ individualProfile.denomination }}</span>
              </div>
              <div class="detail" v-if="individualProfile.gender === 'Male'">
                <strong>{{ translations.tcOccupation }}:</strong>
                <span>{{ individualProfile.occupation }}</span>
              </div>
            </div>
            <div class="col">
              <div class="detail">
                <strong>{{ translations.tcRecommendedBy }}:</strong>
                <span>{{ individualProfile.referral_formal_name }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcLastContactDay }}:</strong>
                <span>{{ formatDate(individualProfile.last_contact_date) }}</span>
              </div>
            </div>
            <div class="col">
              <div class="detail full-detail">
                <strong>{{ translations.tcOtherInformation }}:</strong>
                <span>{{ individualProfile.comment }}</span>
              </div>
            </div>
          </div>
          <b-button
            v-if="toggleMe & iCanSeeAny(secured_profile_edit_controls)"
            variant="primary"
            @click="handleEditClick"
            class=""
            >{{ translations.tcEdit }}</b-button
          >
          <b-button
            v-if="toggleMe & iCanSeeAny(secured_profile_delete_controls)"
            variant="primary"
            class="ml-4"
            @click="handleDeleteClick"
            >{{ translations.tcDelete }}</b-button
          >
        </div>
      </section>
    </page-body>
    <page-footer class="mx-lg mt-5">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJump.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'prospect-details',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      toggle: true,
      toggleMe: true,
      backJumpObj: {
        link: '/programs/mb/prospect-profile',
        location: '',
      },
      secured_profile_delete_controls: {
        delete_candidate_profile_button: '13b8f818-f6f0-4bd0-8924-ae0fa38ad164',
      },
      secured_profile_edit_controls: {
        edit_non_member_profile_button: 'e33e463c-2e77-40c9-9dc7-67aaa9acfed5',
      },
    }
  },
  methods: {
    ...mapActions({
      deleteProspect: 'membership/deleteProspect',
      loadIndividualProfile: 'membership/loadIndividualProfile',
      loadProspects: 'membership/loadProspects',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async pageLoad() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([await this.loadIndividualProfile(), (this.backJumpObj.location = this.indDisplayName)])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    formatDate(thisDate) {
      return !thisDate ? '' : thisDate.substring(0, 10)
    },
    async handleEditClick() {
      this.$router.push({ name: 'individual-details-edit' })
    },
    async handleDeleteClick() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.translations.tcDeleteCandidateOkay,
        confirmButtonText: this.translations.tcOk || 'Ok',
        cancelButtonText: this.translations.tcCancel || 'Cancel',
      }).then((result) => {
        if (result.value) {
          this.deleteTheProspect()
        }
      })
    },
    async deleteTheProspect() {
      let delProspect = {
        prs_ind_key: this.userSelectedIndividualKey,
        prs_change_user_key: this.userIndKey,
        prs_change_user: '',
        prs_delete_flag: true,
      }
      let result = true
      await Promise.all([this.setLoadingStatus(true), (result = await this.deleteProspect(delProspect))]).then(() => {
        this.setLoadingStatus(false)
        this.$swal({
          icon: result ? 'success' : 'error',
          text: result ? this.translations.tcCandidateDeleted : this.translations.tcErrorCandidateNotDeleted,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          if (result.value) {
            this.reloadProspects()
          }
        })
      })
    },
    async reloadProspects() {
      await Promise.all([this.loadProspects()]).then(() => {
        this.$router.push({ name: 'prospects' })
      })
    },
  },
  computed: {
    ...mapGetters({
      iCanSeeAny: 'user/iCanSeeAny',
      individualProfile: 'membership/individualProfile',
      prefCulture: 'user/userPreferredCulture',
      userIndKey: 'user/userId',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
    }),
    indDisplayName() {
      return !!this.individualProfile.informal_name && this.individualProfile.preferred_name === 'I'
        ? `${this.individualProfile.prefix} ${this.individualProfile.informal_name} ${this.individualProfile.last_name}`
        : this.individualProfile.formal_name
    },
  },
  async created() {
    //need a selected individual or redirect back to membership landing
    if (!this.userSelectedIndividualKey) {
      this.$router.push({ name: 'membership' })
    }
    await Promise.all([
      await this.getViewTranslations(),
      await this.getComponentTranslations('back-jump'),
      await this.getComponentTranslations('common.occupation-type'),
      await this.pageLoad(),
    ]).then((results) => {
      this.stripReadableText(results[2])
      const translatedText = {
        ...results[1],
        common: { ...this.translations.common },
      }
      this.$set(this.translations, 'components', translatedText)
      this.setTranslatedOccupationName()
    })
  },
  components: {
    BackJump: BackJump,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
.service-times-presentations {
  .page-header h1 {
    margin-bottom: 50px;
  }
  h2 {
    margin: 0;
    color: #000000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4px;
    line-height: 44px;
    text-transform: uppercase;
    display: flex;
    .i-tooltip {
      position: relative;
      top: -5px;
      margin-left: 10px;
      line-height: 0;
      svg path {
        fill: #000;
      }
    }
  }
  section {
    margin-bottom: 40px;
    .p-4 {
      padding: 40px 30px !important;
      @include breakpoint(sm) {
        padding: 20px 24px 20px 24px !important;
      }
    }
    header {
      display: flex;
    }
    @include breakpoint(sm) {
      .btn {
        width: 100%;
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  @include breakpoint(sm) {
    .section-1 {
      .ml-4 {
        margin-left: 0 !important;
      }
    }
  }
  .service-times,
  .pres-dates {
    margin-top: 35px;
  }
  .pres-dates {
    .date-card {
      width: calc(33.333% - 15px);
      margin-right: 15px;
      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.details2 {
  margin-bottom: 50px;
  @include breakpoint(sm) {
    flex-wrap: wrap;
  }
  .col {
    flex: 0 0 33.3333%;
    width: 33.3333%;
    @include breakpoint(sm) {
      flex: 0 0 100%;
      width: 100%;
    }
  }
  .detail {
    font-size: 15px;
    margin-bottom: 10px;
    strong,
    span {
      display: block;
      color: $gray;
      letter-spacing: 1px;
    }
    strong {
      padding-right: 10px;
      text-transform: uppercase;
      font-weight: 900;
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    span {
      flex: 1 1 auto;
      align-self: flex-end;
    }
    &.full-detail {
      display: block;
    }
  }
}
</style>
